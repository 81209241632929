import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../components/blog/Layout'
import Pagination from '../../components/blog/Pagination'
import PostCard from '../../components/blog/PostCard'
import MetaData from '../../components/common/MetaData'
import LocalizationContext from '../../components/LocalizationContext'
import AdCard from '../../components/blog/AdCard'

import { default as styles } from './IndexTemplate.module.css'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const IndexTemplate = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges

    return (
        <LocalizationContext.Provider value={{ locale: pageContext.language, localizedURL: pageContext.localizedUri }}>
            <MetaData location={location} />
            <Layout isHome={true} pathname={location.pathname}>
                <div className="article-list">
                    <section className={styles.content}>
                        {posts.map(({ node }, i) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <Fragment key={node.id}>
                                <PostCard post={node} />
                                {(i + 1) % 2 === 0 ? (
                                    <AdCard path={location.pathname} />
                                ) : null}
                            </Fragment>
                        ))}
                        {posts.length < 1 ? (
                            <div className={styles.fallback}>
                                {pageContext.language === `fr` ? (
                                    <>Il n&apos;y a aucun article dans cette catégorie</>
                                ) : (
                                    <>There is nothing to show here</>
                                )}
                            </div>
                        ) : null}
                    </section>
                    <Pagination className={styles.content} pageContext={pageContext} />
                </div>
            </Layout>
        </LocalizationContext.Provider>
    )
}

IndexTemplate.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default IndexTemplate

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!, $tagSlug: String! = "hash-fr") {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        filter: {tags: {elemMatch: {slug: {eq: $tagSlug}}}},
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
