import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import PostInfo from '../PostInfo'
import { getPostUri } from '../../../utils/post'

import { default as styles } from './PostCard.module.css'

export const PostCard = ({ post }) => {
    const url = getPostUri(post)

    return (
        <Link to={url} className={styles.postCard}>
            <header className={styles.postCardHeader}>
                <h2 className={styles.postCardTitle}>{post.title}</h2>
                {post.feature_image != null ? (
                    <img className={styles.postCardImage} src={post.feature_image} alt="" />
                ) : null}
            </header>
            <section className={styles.postCardExcerpt}>{post.excerpt}</section>
            <footer className={styles.postCardFooter}>
                <PostInfo post={post} />
            </footer>
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        slug: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
        published_at_pretty: PropTypes.string.isRequired,
    }).isRequired,
}
