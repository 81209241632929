import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../LocalizationContext'

import { default as styles } from './Pagination.module.css'

export const Pagination = ({ pageContext }) => {
    const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext
    const { locale } = useContext(LocalizationContext)

    return (
        <nav className={styles.pagination} role="navigation">
            <div>
                {previousPagePath && (
                    <Link to={previousPagePath} rel="prev">
                        {locale === LOCALES.EN ? `Previous` : `Précédent`}
                    </Link>
                )}
            </div>
            {numberOfPages > 1 && <div className={styles.paginationLocation}>Page {humanPageNumber} {locale === LOCALES.EN ? `of` : `sur`} {numberOfPages}</div>}
            <div>
                {nextPagePath && (
                    <Link to={nextPagePath} rel="next">
                        {locale === LOCALES.EN ? `Next` : `Suivant`}
                    </Link>
                )}
            </div>
        </nav>
    )
}

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired,
}
