import React, { useEffect, useContext } from 'react'
import propTypes from 'prop-types'

import LocalizationContext, { LOCALES } from '../../LocalizationContext'

import { default as styles } from './AdCard.module.css'

export const AdCard = ({ path }) => {
    const { locale } = useContext(LocalizationContext)

    useEffect(() => {
        if (window != null) {
            window.adsbygoogle = window.adsbygoogle || []
            window.adsbygoogle.push({})
        }
    }, [path])

    return (
        <div className={styles.container} key={path}>
            <div className={styles.fallback}>
                <p className={styles.title}>Ad</p>
                {locale === LOCALES.FR ? (
                    <p className={styles.text}>
                        Un publicité devrait s&apos;afficher ici.
                        Aujourd&apos;hui, le contenu gratuit que je vous propose est financé par la publicité.
                        Si vous souhaitez soutenir mon travail, merci de désactiver votre bloqueur de publicité sur travel-and-food.com.
                    </p>
                ) : null}
                {locale === LOCALES.EN ? (
                    <p className={styles.text}>
                        An ad should be displayed here.
                        Today, all the content I make for you is paid by the ads.
                        If you want to support my work, please disactivate your adblock on travel-and-food.com.
                    </p>
                ) : null}
            </div>
            <ins
                className="adsbygoogle"
                style={{ display: `block` }}
                data-ad-format="fluid"
                data-ad-layout-key="-cz+7k+1t-sj+19b"
                data-ad-client="ca-pub-9887891628613990"
                data-ad-slot="8727001282"
                data-adtest={process.env.NODE_ENV === `production` ? `off` : `on`}
            />
        </div>
    )
}

AdCard.propTypes = {
    path: propTypes.string.isRequired,
}
